<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Отчет об успеваемости и качеству знаний учащихся 1-11 классов
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        v-model="filter.educationYear"
                        :options="educationYears"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4" v-if="showMainDepartment()">
                  <b-form-group>
                    <template v-slot:label>
                      Район
                    </template>
                    <b-form-select
                        v-model="filter.regionId"
                        :options="regions"
                        @change="reloadSchools"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Школа
                    </template>
                    <b-form-select
                        v-model="filter.schoolId"
                        :options="schools"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Четверть
                    </template>
                    <b-form-select
                        v-model="filter.quarter"
                        :options="quarterOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
              >{{
                  $t("common.filter")
                }}
              </b-button>

              <b-button type="button" @click="exportData" variant="primary" style="margin-left: 10px"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }"
              >Экспорт</b-button>
            </b-form>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th rowspan="2">№</th>
                <th colspan="12">Классы</th>
                <th rowspan="2">Всего учеников</th>
                <th rowspan="2">Мальчики</th>
                <th rowspan="2">Девочки</th>
              </tr>
              <tr>
                <th>№ СШ</th>
                <th>1 кл.</th>
                <th>2 кл.</th>
                <th>3 кл.</th>
                <th>4 кл.</th>
                <th>5 кл.</th>
                <th>6 кл.</th>
                <th>7 кл.</th>
                <th>8 кл.</th>
                <th>9 кл.</th>
                <th>10 кл.</th>
                <th>11 кл.</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, sk) in data" :key="sk">
                <td>{{sk+1}}</td>
                <td>{{ item.unitName }}</td>
                <td>{{ item.level1 }}</td>
                <td>{{ item.level2 }}</td>
                <td>{{ item.level3 }}</td>
                <td>{{ item.level4 }}</td>
                <td>{{ item.level5 }}</td>
                <td>{{ item.level6 }}</td>
                <td>{{ item.level7 }}</td>
                <td>{{ item.level8 }}</td>
                <td>{{ item.level9 }}</td>
                <td>{{ item.level10 }}</td>
                <td>{{ item.level11 }}</td>
                <td>{{ item.levelAll }}</td>
                <td>{{ item.girls }}</td>
                <td>{{ item.boys }}</td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import DictionariesService from "@/common/dictionaries.service";
import ApiService from "@/common/api.service";
import {mapGetters} from "vuex";

export default {
  name: "StudentCountByLevelReport",
  components: {
    KTPortlet
  },
  data() {
    return {
      filter: {
        quarter: 1,
        regionId: 0,
        schoolId: 0,
        educationYear: DictionariesService.currentYear(),
      },
      data: {},
      quarterOptions: DictionariesService.quarterOptions(),
      educationYears: DictionariesService.educationYearOptions(),
      loading: false,
      regions: [],
      schools: [],
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  mounted() {
    ApiService.querySecured("dictionaries/regions", {}).then(({data}) => {
      this.regions = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });

    ApiService.querySecured("dictionaries/schools", {}).then(({data}) => {
      this.schools = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
  },
  methods: {
    reloadData() {
      let $this = this;
      $this.loading = true;
      ApiService.postSecured("educationDepartment/studentCountByLevelDepartment", this.filter).then(({data}) => {

        $this.data = data;
        
        console.log($this.data);
      }).catch(({response}) => {
        console.log(response);
      }).finally(function () {
        $this.loading = false;
      });
    },
    reloadSchools() {
      ApiService.querySecured("dictionaries/schools",
          {params: {regionId: this.filter.regionId}})
          .then(({data}) => {
            data.dictionary.unshift({value: 0, text: ''});
            this.schools = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });
    },
    exportData() {
      let filters = this.filter;
      let $this = this;
      $this.loading = true;
      filters['export'] = true;
      ApiService.downloadSecured("educationDepartment/eduProgressReportDepartment", filters)
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Отчет.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({response}) => {
            console.log(response);
          }).finally(function () {
        $this.loading = false;
      });
    },
    showMainDepartment() {
      return this.currentUser.role == 'ROLE_MAIN_EDUCATION_DEPARTMENT';
    }
  }
};
</script>
